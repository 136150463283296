<template>
  <div id="app">
    <div class="head">
      <div class="head-info">
        <img class="logo" src="./assets/logo.png" alt="">
        <div class="name">云上可道</div>
      </div>
      <div class="head-tab">
        <div @click="tabchange(item.index)" :class="item.value == active ? 'tab-item-active' : 'tab-item'"
          v-for="(item, index) in tab" v-bind:key="index">{{ item.title }}</div>

      </div>
    </div>
    <div style="position: relative;">
      <img class="bg-img" src="./assets/bg.png" alt="">
      <div class="download">
        <a href="" download>
          <div class="download-item">
            <img style="width: 25px;height: 25px;margin-right: 10px;" src="./assets/iphone.png" alt="">
            <div>iphone版</div>
          </div>
        </a>
        <a href="" download>
          <div class="download-item">

            <img style="width: 25px;height: 25px;margin-right: 10px;" src="./assets/android.png" alt="">
            <div>Android版</div>

          </div>
        </a>
      </div>
    </div>
    <!-- <DownLoad v-if="active==3"></DownLoad> -->
    <div class="foot">
      <div style="width: 100%;display: flex;">
        <div>
          <img class="qrcode" src="./assets/QRcode.png" alt="">
        </div>
        <div class="foot-right">
          <div class="foot-item">
            <div class="foot-info" style="font-size: 18px;">关于我们</div>
            <div class="foot-info">全部产品</div>
            <div class="foot-info">下载链接</div>
            <div class="foot-info">联系我们</div>
            <div class="foot-info">人才招聘</div>
            <div class="foot-info">公司官网</div>
          </div>
          <div class="foot-item">
            <div class="foot-info" style="font-size: 18px;">法律协议</div>
            <div class="foot-info">服务条款</div>
            <div class="foot-info">隐私政策</div>
            <div class="foot-info">更多协议</div>
          </div>
          <div class="foot-item">
            <div class="foot-info" style="font-size: 18px;">服务与支持</div>
            <div class="foot-info">网站备案</div>
            <div class="foot-info">服务咨询</div>
            <div class="foot-info">建议与反馈</div>
            <div class="foot-info">举报投诉</div>
            <div class="foot-info">公司官网</div>
          </div>
          <div class="foot-item">
            <div class="foot-info" style="font-size: 18px;">联系我们</div>
            <div class="foot-info">地址：山东省青岛市黄岛区长江财富中心29层</div>
          </div>
        </div>
      </div>
      <div class="filings">
        鲁ICP备2022000356号-3 鲁公网安备 33011002011859号
      </div>


    </div>
  </div>
</template>
<script>
  // import DownLoad from './components/download.vue';
export default {
  // components:{
  //   DownLoad 
  //   },
  data() {
    return {
      tab: [
        {
          title: "首页",
          value: "1"
        },
        {
          title: "产品",
          value: "2"
        },
        {
          title: "下载",
          value: "3"
        },
        {
          title: "招商",
          value: "4"
        }, {
          title: "联系我们",
          value: "5"
        },


      ],
      active: 3
    }
  },
  created() {

  },
  methods: {
    tabchange(index) {
      if (index != 3) {
        alert("敬请期待")
      }
    }
  }
}
</script>

<style scoped>
body {
  margin: 0 !important;
  padding: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}

.head {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 18px 0;
  width: 100%;
  box-sizing: border-box;
}

.head-info {
  display: flex;
  align-items: center;
  margin-left: 360px;
  width: 40%;
  /* width: 100%; */
  box-sizing: border-box;

}

.logo {
  width: 50px;
}

.name {
  color: #435A92;
  font-size: 24px;
  margin-left: 20px;
}

.head-tab {
  width: 40%;
  display: flex;
  font-size: 18px;
  color: #666670;
  align-items: center;
}

.tab-item {
  width: 150px;
  text-align: center;
}

.tab-item-active {
  width: 150px;
  text-align: center;
  color: #435A92;
  font-weight: bold;
}

.bg-img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.download {
  position: absolute;
  /* width: 200px; */
  /* border: solid red 1px; */
  left: 300px;
  bottom: 400px;
  display: flex;
}

.download-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 36px 36px 36px 36px;
  opacity: 0.8;
  margin: 0 30px;
}

a {
  text-decoration: none;
  /* 移除下划线 */
  color: inherit;
}

.foot {
  display: flex;
  background: #F7F7F7;
  margin: 60px 0 0 0;
  padding: 50px 0;
  flex-direction: column;
}

.qrcode {
  width: 180px;
  height: 180px;
  margin-left: 360px;
}

.foot-right {
  display: flex;
  flex-direction: row;
  margin-left: 280px;
}

.foot-item {
  display: flex;
  flex-direction: column;
  margin-left: 80px;

}

.foot-info {
  font-size: 14px;
  color: #29303E;
  height: 40px;
  line-height: 40px;
}

.filings {
  text-align: center;
  width: 100%;
  /* border-top: 1px solid #707070; */
  margin-top: 80px;
  font-size: 14px;
  color: #29303E;
  height: 20px;
  line-height: 20px;
  padding-top: 30px;
}
</style>
